<template>
  <page title="支付完成" bg-color="white">
    <div class="text-center py-6 px-8">
      <div class="flex justify-center">
        <icon icon="icon-seleted" size="48" wrap-size="80" bg-color="green-500" color="white" class="rounded-full"></icon>
      </div>
      <div class="mt-4 text-green-500">支付成功</div>
      <div class="mt-4 text-red-500 text-xl font-medium">98888.00 元</div>
      <div class="mt-4 text-sm text-gray-light leading-6">
        感谢您的选购，我们会尽快安排发货，如有疑问，请咨询在线客服
      </div>
      <btn class="mt-10" color="gray-500" outline url="/">返回首页</btn>
    </div>
  </page>
</template>

<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>